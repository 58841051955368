<template>
  <div class="content-box-padding">
    <p class="line-text">
      “汇聚全球一流人才，推动创新成果转化”。依托教育部国际联合实验室，在南京大学、南京市支持下，研究院组建了一支以首席科学家为产研顾问、南京大学教授为核心人员的创业队伍，包括中科院院士2人，国家杰青2人，国家优青1人，省特聘教授1人。硕士及以上人才比列高达90%，孵化引进集群中创业人才均为国内外“双一流”高校研究生学历，同时也是南京大学等双一流高校签约实习基地。通过高水平国际合作平台，研究院将在招才、引才、成才等方面全面打造国际一流人才汇聚机制。
    </p>
    <img
      src="~assets/image/about_us/人才团队1.png"
      alt="人才团队1"
      class="img-center"
    />
    <img
      src="~assets/image/about_us/人才团队2.png"
      alt="人才团队2"
      class="img-center"
      style="margin-top: -70px"
    />
  </div>
</template>
